import './App.css';
import {Home, Miuda, Miudas, Aviso, Sobre, SerMiudaBoa} from './pages'
import {BrowserRouter, Route, Routes} from 'react-router-dom'




function App() {

  

  return (    
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Aviso />} />
        <Route path="/inicio" element={<Home />} />
        <Route path="/sobre" element={<Sobre />} />
        {/* <Route path="/contacto" element={<Contactos />} /> */}
        <Route path="/miudas" element={<Miudas />} />
        <Route path="/ser-miuda-boa" element={<SerMiudaBoa />} />
        <Route path="/:slug" element={<Miuda />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
