import React, {useEffect} from 'react';
import { Layout, Menu, theme, Image} from 'antd';
import LOGO from '../assets/img/logo.png'
import LOGO2 from '../assets/img/logoB2.png'
import formulario from '../assets/pdf/Formulário de Inscrição para o site oogame.pdf'
import {Link, useNavigate} from 'react-router-dom'
import {
  UserOutlined,
  HomeOutlined,
  TrophyOutlined
} from '@ant-design/icons'; 


const { Header, Content, Footer } = Layout;

const items = [ 
  {icon: HomeOutlined,link: "Inicio"},
  {icon: UserOutlined,link: "Miudas"},
  // {icon: UserSwitchOutlined,link: "Massagistas"},
  {icon: TrophyOutlined,link: "Sobre"},
  // {icon: ContactsOutlined,link: "Contactos"},
  // {icon: ProfileOutlined,link: "Meu perfil"},
  // {icon: LockOutlined,link: "Cadastrar - se"},
].map((element, index) => ({
  key: `${element.link}`,
  icon: React.createElement(element.icon),
  label: `${element.link}`,
}));
const Miudas = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  
  const navigate = useNavigate();

  useEffect(()=>{
    document.title = 'Miudas Boas -  Sobre o site'     
    
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })

  },[]) 
  

  return (
    <Layout>
      <Header
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: "#FF1FA6",
          fontWeight: 500,
          marginBottom:0
        }}
      >
        <div className="demo-logo-vertical" style={{textAlign: 'center',}} >
          <Image
            width={130}
            src={LOGO}
          />
        </div>

        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={['2']}
          onClick={({ item, key, keyPath, domEvent }) => navigate("/"+key)}
          items={items}
          style={{
            flex: 1,
            minWidth: 0,
            backgroundColor: "#FF1FA6",
            fontSize:18
          }}
        />
      </Header>
      
      <Content
        style={{
          padding: '0 10px',
        }}
      >
        
        
        <div
          style={{
            background: colorBgContainer,
            minHeight: 280,
            padding: 1,
            borderRadius: borderRadiusLG,
            fontSize:"1.7em"
          }}
        >
            <div className="container-fluid mt-4">
        <h1 className="text-center mb-0 cor pl-5 pr-5 text-uppercase">Nosso portal</h1>
        <h3 className="text-center mb-0 cor pl-5 pr-5 pb-5 text-lighter">Nós somos o melhor site
            de
            acompanhantes
            em
            Angola</h3>
        <div className="container text-justify text-white">
            <p>Nós temos grandes planos para o <strong>MIUDAS BOAS</strong> e somos uma equipe profissional, com
                experiência e potencial para realizá-los.</p>

            <p>Acreditamos que os acessos com celular e tablet continuarão crescendo e por isso desenvolvemos o Garota
                Linda com foco nesse tipo de acesso.</p>

            <blockquote>
                <p>Estamos preparados para aparecer rapidamente, com privacidade e qualidade em todos os modelos de
                    celulares e tablets, e este é um grande diferencial para o site. Mas é só o começo.</p>
            </blockquote>

            <p>Esta é a segunda versão do site e nós analisamos e melhoramos continuamente para que
                ele se firme como o melhor site da área em Luanda, Benguela, Huambo, Huila e um dos melhores de Angola.
                Aguardem as novidades!</p>

            <h2 className="text-left">ANUNCIE COM A GENTE</h2>

            <p>O MIUDAS BOAS está muito bem posicionados nas buscas do Google para pesquisas feitas em computadores,
                tablets e celulares, isso torna o site muito conhecido e faz com que os anúncios das <Link
                   to="/miudas">acompanhantes angolanas</Link> e acompanhantes
                terceiras recebam milhares de
                visualizações todos os dias.</p>

            <p>Nosso site já tem tradição em Angola e é muito bem gerenciado, com um atendimento de primeira!</p>

            <h2 className="vermelho mt-3 mb-3 text-left">O QUE VOCÊ PRECISA PARA ANUNCIAR?</h2>

            <p>Para ter o seu anúncio no site você precisa mandar as informações necessárias:</p>

            <details>
                <summary>Clique aqui para ver as informações necessárias <i className="fa fa-angle-down"
                        aria-hidden="true"></i></summary>
                <ul>
                    <li><strong className="vermelho">01.</strong> Nome:</li>
                    <li><strong className="vermelho">02.</strong> Idade:</li>
                    <li><strong className="vermelho">03.</strong> Altura:</li>
                    <li><strong className="vermelho">04.</strong> Peso:</li>
                    <li><strong className="vermelho">05.</strong> Tamanho dos pés:</li>
                    <li><strong className="vermelho">06.</strong> Cor dos olhos:</li>
                    <li><strong className="vermelho">07.</strong> Cor dos cabelos:</li>
                    <li><strong className="vermelho">08.</strong> Manequim:</li>
                    <li><strong className="vermelho">09.</strong> Quadril:</li>
                    <li><strong className="vermelho">10.</strong> Seios:</li>
                    <li><strong className="vermelho">11.</strong> Cintura:</li>
                    <li></li>
                    <li><strong className="vermelho">12.</strong> Perfil: <em>ex: Menininha safada…</em></li>
                    <li><strong className="vermelho">13.</strong> Tipo físico: <em>ex: Gostosa, Mignon, Ninfetinha…</em>
                    </li>
                    <li><strong className="vermelho">14.</strong> Tem tatuagens? Quantas?</li>
                    <li><strong className="vermelho">15.</strong> Tem piercings? Quantos?</li>
                    <li><strong className="vermelho">16.</strong> É fumante?</li>
                    <li><strong className="vermelho">17.</strong> Nível cultural:</li>
                    <li><strong className="vermelho">18.</strong> Idiomas:</li>
                    <li><strong className="vermelho">19.</strong> Signo:</li>
                    <li><strong className="vermelho">20.</strong> Faz anal?</li>
                    <li><strong className="vermelho">21.</strong> Faz oral?</li>
                    <li><strong className="vermelho">22.</strong> Faz vaginal?</li>
                    <li></li>
                    <li><strong className="vermelho">23.</strong> Bairro:</li>
                    <li><strong className="vermelho">24.</strong> Atende quem? <em>(homens, mulheres, casais, duplas…)</em>
                    </li>
                    <li><strong className="vermelho">25.</strong> Atende onde? <em>(seu local, escritórios, hotéis,
                            motéis…)</em></li>
                    <li><strong className="vermelho">26.</strong> Atende quando? <em>(24 horas, de segunda a sexta)</em>
                    </li>
                    <li><strong className="vermelho">27.</strong> Atende em qual período? <em>(das 9 às 23 horas)</em></li>
                    <li></li>
                    <li><strong className="vermelho">28.</strong> Cachê por meia hora:</li>
                    <li><strong className="vermelho">29.</strong> Cachê por uma hora:</li>
                    <li><strong className="vermelho">30.</strong> Cachê por duas horas:</li>
                    <li><strong className="vermelho">31.</strong> Cachê para passar a noite:</li>
                    <li><strong className="vermelho">32.</strong> Cachê pelo final de semana:</li>
                    <li><strong className="vermelho">33.</strong> Formas de pagamento <em>(Débito, crédito, dinheiro, quais
                            cartões aceita…)</em>:</li>
                    <li></li>
                    <li><strong className="vermelho">34.</strong> Data do ensaio:</li>
                    <li><strong className="vermelho">35.</strong> Produção do ensaio <em>(fotógrafo)</em>:</li>
                    <li></li>
                    <li><strong className="vermelho">36.</strong> Fotos para publicação no anúncio:</li>
                    <li><strong className="vermelho">37.</strong> Foto da sua identidade:</li>
                    <li><strong className="vermelho">38.</strong> Vídeo ou áudio:</li>
                </ul>

                <h2 className="text-left">Algumas observações:</h2>
                <p>Não existe limite de fotos para colocar no anúncio. Podem ser quantas fotos você quiser, de até 2
                    ensaios diferentes, que podem aparecer separados na pagina do anúncio.</p>
                <p>Nós podemos cobrir o seu rosto nas fotos do anúncio, mas não tiramos tatuagens e nem fazemos edições
                    mais elaboradas nas fotos. Podemos indicar um webdesigner para realizar estes serviços.</p>
                <p>Anúncios que mostram o rosto recebem muito mais acessos e chamam mais a atenção. O mesmo acontece com
                    anúncios com vídeos ou áudios.</p>
                <p>Se quiser, você pode mandar links dos seus videos e pode ter dois ensaios de fotos diferentes. Os
                    anúncios com mais imagens e com videos são muito mais acessados e dão um retorno bem maior.</p>
                <p>Os anúncios precisam ser aprovados pelo nosso site. Entre outras coisas, nós verificamos se o anúncio
                    usa imagens falsas. Se por qualquer motivo a nossa equipe não aprovar as fotos, nós vamos pedir para
                    você enviar um vídeo para a validação do anúncio.</p>
                <p>Anúncios com imagens fake serão retirados do ar e não serão reembolsados.</p>
                <p>A qualquer momento você poderá editar o seu anúncio, mudando o texto e as fotos. Você só precisa
                    pedir a edição pelo WhatsApp.</p>
                <p>Não aceitamos anúncios em que a idade seja diferente da sua idade real em mais ou menos 5 anos. Sua
                    idade real será confirmada pelo seu documento de identificação.</p>
            </details>

            <p className="form mt-3"><a title="Preencher o formulário"
                    href={formulario} download={true} rel="nofollow"
                    className="bota">Clique aqui para preencher o formulário e enviar as informações <i
                        className="fa fa-long-arrow-right" aria-hidden="true"></i></a></p>

            <h2 className="vermelho mt-3 mb-3 text-left">QUANTO CUSTA ANUNCIAR NO MIUDAS BOAS?</h2>

            <p>Fale com a gente pelo WhatsApp, vamos conversar, você vai gostar da nossa proposta e terá resultados
                muito bons anunciando no MIUDAS BOAS!</p>

            <p>Se você parar um pouco e pensar bem, os valores dos anúncios valem a pena e são muito compensadores,
                principalmente quando você compara com anúncios de jornais, que só aparecem por um dia e depois disso
                não são mais vistos. No Garota Linda os anúncios das <Link
                    to="/ser-miuda-boa">garotas de programa
                    angolanas</Link> são divulgados por mais tempo e aparece para as pessoas que estão buscando pelo
                serviço que
                você oferece. Vale a pena colocar o seu anúncio no MIUDAS BOAS!</p>

            <p>Os anúncios só serão publicados depois que o comprovante do pagamento for enviado e que a comprovação do
                mesmo for feita pela nossa equipe.</p>

            <p>Geralmente, depois da comprovação do pagamento, nós não demoramos mais do que 12 hora para publicar o
                anúncio.</p>

            <h2 className="text-left">FALE COM A GENTE</h2>

            <p>Se você tiver qualquer dúvida, é só entrar em contato com a gente ou mandar uma mensagem:</p>

            <p>
              <strong className="vermelho">Telefone e WhatsApp:</strong> 
              <a title="Clique para chamar no WhatsApp"
                    href="https://web.whatsapp.com/send?phone=244950287796&amp;text=Olá, tudo bem? Eu estou na página de contato do MIUDAS BOAS"
                    target="_blank" rel="nofollow noopener noreferrer"> (244) 950 287 796</a>
                    <br/>
                <strong className="vermelho">E-mail:</strong> <a href="mailto:ola@miudasboas.com">ola@miudasboas.com</a><br/>
            </p>

        </div>
        </div>

        </div>
      </Content>
      <Footer
           style={{
             textAlign: 'center',
             color:"#FFFFFF",
             fontSize:20,
             backgroundColor:"#364D79" 
         }}
         >
         <div className="demo-logo-vertical" style={{textAlign: 'center',}} >
          <Image
            width={250}
            src={LOGO2}
            />
        </div>
            Todos os direitos reservados ©{new Date().getFullYear()} Criado por MIUDAS BOAS
         </Footer>
    </Layout>
  );
};
export default Miudas;