import React, {useEffect} from 'react';
import { Layout, Menu, theme, Image} from 'antd';
import LOGO from '../assets/img/logo.png'
import LOGO2 from '../assets/img/logoB2.png'
import formulario from '../assets/pdf/Formulário de Inscrição para o site oogame.pdf'
import { useNavigate} from 'react-router-dom'
import {
  UserOutlined,
  HomeOutlined,
  TrophyOutlined
} from '@ant-design/icons';


const { Header, Content, Footer } = Layout;

const items = [ 
  {icon: HomeOutlined,link: "Inicio"},
  {icon: UserOutlined,link: "Miudas"},
  // {icon: UserSwitchOutlined,link: "Massagistas"},
  {icon: TrophyOutlined,link: "Sobre"},
  // {icon: ContactsOutlined,link: "Contactos"},
  // {icon: ProfileOutlined,link: "Meu perfil"},
  // {icon: LockOutlined,link: "Cadastrar - se"},
].map((element, index) => ({
  key: `${element.link}`,
  icon: React.createElement(element.icon),
  label: `${element.link}`,
}));
const Miudas = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  
  const navigate = useNavigate();

  useEffect(()=>{
    document.title = 'Miudas Boas -  Quero ser das miudas boas'  
  },[]) 
  

  return (
    <Layout>
      <Header
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: "#FF1FA6",
          fontWeight: 500,
          marginBottom:0
        }}
      >
        <div className="demo-logo-vertical" style={{textAlign: 'center',}} >
          <Image
            width={130}
            src={LOGO}
          />
        </div>

        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={['2']}
          onClick={({ item, key, keyPath, domEvent }) => navigate("/"+key)}
          items={items}
          style={{
            flex: 1,
            minWidth: 0,
            backgroundColor: "#FF1FA6",
            fontSize:18
          }}
        />
      </Header>
      
      <Content
        style={{
          padding: '0 10px',
        }}
      >
        
        
        <div
          style={{
            background: colorBgContainer,
            minHeight: 280,
            padding: 24,
            borderRadius: borderRadiusLG,
            fontSize:"1.7em"
          }}
        >
            <div className="container-fluid mt-5">
        <h1 className="text-center mb-0 cor pl-5 pr-5 text-uppercase">COMEÇAR A SER GAMER</h1>
        <h3 className="">Para fazer parte do portal deverá seguir os procedimentos
            abaixo
        </h3>
        <div className="container-fluid text-center">
            <p className="text-justify text-white-50 mb-5">
                1º Obrigatóriamente deve ser maior de idade (18 anos em diante)
                <br /><br />
                2º Baixe nosso documento em PDF para preencher seus dados. <a
                    href={formulario} download={true} >click para
                    baixar</a>
                <br /><br />
                3º Envie o documento preenchido para o nosso email ou redes sociais abaixo (no rodapé)

                <br /><br />
                4º Junto com o documento, envie suas fotos sensuais com alguns requisitos
                <br />
                - Documento que comprova que é maior de idade<br />
                - Fotos com qualidade, sem sensuras e nitidas, (com roupas, biquinis, nuas)<br />
                - Fotos modernas, com poses divercificadas e não redundantes<br />
                - Videos, com qualidade, sensual, provocante e com roteiro bem elaborado<br />
                - Não devem ter rotúlos (nomes ou números vinculados)<br />
                - Não enviar fotos abaixo de 30 e actualizar pelo menos 2 vezes por mês<br />
                - Não nos responsabilizamos pela sua identidade (verifique elas antes de enviares)
                <br /><br />
                5º - Ao entrar deve pagar sua inscrição por <b>10.000</b> kz (1 mês gratís pela primeira vez). Após a inscrição e o periudo gratis de 1 mês para permanência
                da sua marca ou perfil, é cobrado <b>25.000</b> kz mensalmente. Se estiver fora de Angola converta para sua
                moeda
                <br /><br />
                6 º Para pagamentos, é feito por lavantamento sem cartão em Angola para assegurar sua identidificação
                bancaria e nossa. <br />
                Os codigos de lavantamento e PIN devem ser enviados em nosso email ou pelo nosso whatsapp. Outros
                metodos são invalidos
                <br /><br />
                7 º Deverá pertencer em nossos grupos de whatsapp com suas colegas para conversar com nossos clientes
                por pelo menos 3 vezes por semana e sem fazer publidades externas.
                Caso não cumpra será banida
                <br /><br />
                8 º Se pretender ser Miuda Boa oficial do portal (Ser nossa parceira ou parceiro) ou pretender outros
                serviços
                nos
                contacte aqui no portal ou em
                nossas
                redes socias. Obrigado

            </p>

            <p>
              <strong className="vermelho">Movél e WhatsApp:</strong> 
              <a title="Clique para chamar no WhatsApp"
                    href="https://web.whatsapp.com/send?phone=244921442476&amp;text=Olá, tudo bem? Eu estou na página de contato do MIUDAS BOAS"
                    target="_blank" rel="nofollow noopener noreferrer"> (244) 921 442 476</a>
                    <br/>
                <strong className="vermelho">E-mail:</strong> <a href="mailto:ola@miudasboas.com">ola@miudasboas.com</a><br/>
            </p>

        </div>
      </div>

        </div>
      </Content>
      <Footer
           style={{
             textAlign: 'center',
             color:"#FFFFFF",
             fontSize:20,
             backgroundColor:"#364D79"
         }}
         >
         <div className="demo-logo-vertical" style={{textAlign: 'center',}} >
          <Image
            width={250}
            src={LOGO2}
            />
        </div>
            Todos os direitos reservados ©{new Date().getFullYear()} Criado por MIUDAS BOAS
         </Footer>
    </Layout>
  );
};
export default Miudas;