import React from 'react'
import {Link} from 'react-router-dom'
import logo from '../assets/img/logoB2.png'
import {RedesSocias} from '../components'

function Aviso() {
  document.title = 'Miudas Boas -  Alerta antes de entrar'
  return (
      <div className="aviso" style={{fontSize:"1.2rem"}}>
        <img src={logo} className="logo" alt="logo miudas boas" />
        <h1> MIUDAS BOAS É UM PORTAL DE ACOMPANHANTES PROFISSIONAIS E DE OCASIÃO</h1>
        <h4>Olá!</h4>
        <p>A Miudas Boas, um portal de classificados para serviços de acompanhante. Todo o material contido neste site é destinado a adultos. Caso você ainda não tenha completado a maior idade não prossiga.</p>

        <p>Nosso site não trabalha como agência e não assume nenhuma responsabilidade sobre os anúncios contidos. Todas as nossas anunciantes tem mais de 18 anos.</p>

        <p>A Procura por Acompanhantes tem crescido muito, em virtude de sua qualidade. Podendo ser para o seu total prazer ou para ocasiões como festas, eventos ou apenas quando se procura uma boa companhia. Em nosso pais, as mulheres são consideradas lindas. Isto por todo o restante do mundo.</p>

        <p>E em nossa cidade de Luanda , as Acompanhantes tem sido uma boa opção de companhia. Educadas e muito bonitas oferecem seus serviços sempre com qualidade e descrição total. As acompanhantes de Miudas Boas vem de todas as partes do país, devido a nossa proximidade a capital nacional temos também um grande fluxo de pessoas, o que faz com que você possa escolher entre inúmeras opções de biótipo para escolher a garota que mais lhe agrada, seja para um momento de prazer, festas, eventos e muito mais. Entre e fique a vontade para escolher a Acompanhantes em Angola que mais lhe agradar.</p>

        <p>Somos o maior portal de anúncios de acompanhantes de Angola e buscamos sempre oferecer o mais alto nível de garotas de programa para seus momentos de prazer. Lembrando que nossa página é destinada a adultos que procura por qualidade e descrição em seus encontros.</p>
        <RedesSocias cor='#FF1FA6'/>
        <Link to={`${window.location.origin}/inicio`} className='entrar'>Sou maior. Clica para entrar</Link>
      </div>
  )
}


export default Aviso
