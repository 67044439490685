import React, {useEffect, useState} from 'react';
import { Layout, Menu, Button, theme, Image, Typography, Card, Breadcrumb, Flex, Carousel, Skeleton  } from 'antd';
import LOGO from '../assets/img/logo.png'
import LOGO2 from '../assets/img/logoB2.png'
import IMGPUBLICITE from '../assets/img/PUBLICITE.png'
import {AXIOS} from '../Config'
import {useNavigate} from 'react-router-dom'
import CountUp from 'react-countup';
import {
  UserOutlined,
  EnvironmentOutlined,
  HomeOutlined,
  TrophyOutlined
} from '@ant-design/icons';

var sectionStyle = {
  width: "100%",
  height: "400px",
  backgroundImage: "url(" + IMGPUBLICITE + ")",
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat'
};


const { Header, Content, Footer } = Layout;
const { Title } = Typography;
const { Meta } = Card;

const items = [ 
  {icon: HomeOutlined,link: "Inicio"},
  {icon: UserOutlined,link: "Miudas"},
  // {icon: UserSwitchOutlined,link: "Massagistas"},
  {icon: TrophyOutlined,link: "Sobre"},
  // {icon: ContactsOutlined,link: "Contactos"},
  // {icon: ProfileOutlined,link: "Meu perfil"},
  // {icon: LockOutlined,link: "Cadastrar - se"},
].map((element, index) => ({
  key: `${element.link}`,
  icon: React.createElement(element.icon),
  label: `${element.link}`,
}));
const App = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [miudas,setMiudas] = useState([])
  const [miudasM,setMiudasM] = useState([])
  // const [novidade,setNovidade] = useState([])
  const [contador,setContador] = useState({nFotos:{n:0}, nVisitantes:{n:0}, nMiudas:{n:0}})
  const navigate = useNavigate();
  const [load, setLoad] = useState(true);

  useEffect(()=>{
    document.title = 'Miudas Boas - Acompanhantes modernas'
      async function Axios(){
        const response = await AXIOS.get('/')
        setMiudas(response.data.miudas)
        setMiudasM(response.data.miudasMacho)
        // setNovidade(response.data.novasMiudas)
        setContador(response.data.contadores)
        setLoad(false)

        console.log(response.data.contadores)
      }

      Axios()
      
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      
  },[])

  return (
    <Layout>
      <Header
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: "#FF1FA6",
          fontWeight: 500,
          marginBottom:0
        }}
      >
        <div className="demo-logo-vertical" style={{textAlign: 'center',}} >
          <Image
            width={130}
            src={LOGO}
          />
        </div>

        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={['1']}
          onClick={({ item, key, keyPath, domEvent }) => navigate("/"+key)}
          items={items}
          style={{
            flex: 1,
            minWidth: 0,
            backgroundColor: "#FF1FA6",
            fontSize:18
          }}
        />
      </Header>
      <Carousel style={{marginTop:0, width:"100%", height:400}} effect="fade" arrows={true} autoplay>
        <div>
          <div style={ sectionStyle } />
        </div>
      </Carousel>
      <Content
        style={{
          padding: '0 10px',
        }}
      >
        <Breadcrumb
          style={{
            margin: '16px 0',
          }}
        >
          {/* <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
          <Breadcrumb.Item>App</Breadcrumb.Item> */}
        </Breadcrumb>
        <div
          style={{
            background: "#FF1FA6",
            padding: 24,
            color: "#FFFF",
            borderRadius: borderRadiusLG,
            marginBottom:10
          }}
        >
          {load ? <Skeleton round /> : <Flex wrap='wrap' justify='space-around' gap="large" >
              <div>
                <Title level={1} style={{fontWeight:900,color: "#FFFF", fontSize:50}}>MIDIAS</Title>
                <Title style={{fontWeight:700,fontSize:50,marginTop:-20}}><CountUp end={contador.nFotos.n} separator="." /></Title>
              </div>
              <div>
                <Title level={1} style={{fontWeight:900,color: "#FFFF", fontSize:50}}>PUBLICO</Title>
                <Title style={{fontWeight:700,fontSize:50,marginTop:-20}}><CountUp end={contador.nVisitantes.n} separator="." /></Title>
              </div>
              <div>
                <Title level={1} style={{fontWeight:900,color: "#FFFF", fontSize:50}}>PRAZEROSAS</Title>
                <Title style={{fontWeight:700,fontSize:50,marginTop:-20}}><CountUp end={contador.nMiudas.n} separator="." /></Title>
              </div>
          </Flex>}
        </div>
        <div
          style={{
            background: colorBgContainer,
            minHeight: 280,
            // padding: 24,
            borderRadius: borderRadiusLG,
          }}
        >
          
          <div
            style={{
              // padding: 24,
              textAlign: 'center',
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
              fontSize:"1.7em"
            }}
          >
              <Title style={{textAlign:"left",color:"#FF1FA6",fontWeight:900,fontSize:50, paddingLeft:48, paddingTop:30}}>NOSSAS MIUDAS</Title>
              <Title level={3} style={{textAlign:"left", marginTop:-20, marginBottom:50,fontSize:30, paddingLeft:48}}>Deslumbri-se com as nossas assinantes do portal e maque um momento com elas</Title>
              
              {load ? <Skeleton round /> : <Flex wrap='wrap' justify='center' gap="large" className='miudasB' style={{marginBottom:100}}>
              
                
                  {miudas.map(el=>(
                      <Card
                          hoverable
                          //  style={{ width: 380, fontSize:20 }}
                          //  cover={<Image src={"https://scene7.zumiez.com/is/image/zumiez/product_main_medium/Foos-Gone-Wild-Brown-Hoodie-_377280-front-US.jpg"} height={500} />}
                           cover={<Image src={el.photo} height={500} />}
                           key={el.id_gamer} className="miuda"
                        >
                          <Meta className='titulo-nome' title={<span level={3} style={{fontWeight:"900!important", textTransform:'uppercase'}}>{el.nome + ' ' + el.sobrenome}</span>}/>
                          <Title style={{marginTop:5}} level={5}>@{el.slug} | <EnvironmentOutlined /> {el.cidade}</Title>
                          <Button className='botao-perfil' onClick={()=>navigate(`/${el.slug}`)} style={{backgroundColor:"#FF1FA6", color:"white", marginTop:-30}} shape="round" icon={<UserOutlined />} size="middle" >Meu perfil</Button>
                        </Card>
                        ))}

              </Flex>}
              
              <Title style={{textAlign:"left",color:"#FF1FA6",fontWeight:900,fontSize:50, paddingLeft:48, paddingTop:30}}>NOSSOS BOYZ</Title>
              <Title level={3} style={{textAlign:"left", marginTop:-20, marginBottom:50,fontSize:30, paddingLeft:48}}>Para mulheres insatisfeitas ou procurando de um caso sem compromisso</Title>
              
              {load ? <Skeleton size="large" round /> : <Flex wrap='wrap' justify='center' gap="large" className='miudasB'>
              
              {miudasM.map(el=>(
                      <Card
                        hoverable
                          //  style={{ width: 380, fontSize:20 }}
                          //  cover={<Image src={"https://scene7.zumiez.com/is/image/zumiez/product_main_medium/Foos-Gone-Wild-Brown-Hoodie-_377280-front-US.jpg"} height={500} />}
                           cover={<Image src={el.photo} height={500} />}
                           key={el.id_gamer} className="miuda" 
                        >
                          <Meta className='titulo-nome' title={<span level={3} style={{fontWeight:"900!important", textTransform:'uppercase'}}>{el.nome + ' ' + el.sobrenome}</span>}/>
                          
                          <Title style={{marginTop:5}} level={5}>@{el.slug} | <EnvironmentOutlined /> {el.cidade}</Title>
                          <Button className='botao-perfil' onClick={()=>navigate(`/${el.slug}`)} style={{backgroundColor:"#FF1FA6", color:"white", marginTop:-30}} shape="round" icon={<UserOutlined />} size="middle" >Meu perfil</Button>
                        </Card>
                        ))}
              </Flex>}
          </div>

        </div>
      </Content>
      <Footer
           style={{
             textAlign: 'center',
             color:"#FFFFFF",
             fontSize:20,
             backgroundColor:"#364D79"
         }}
         >
         <div className="demo-logo-vertical" style={{textAlign: 'center',}} >
          <Image
            width={250}
            src={LOGO2}
            />
        </div>
            Todos os direitos reservados ©{new Date().getFullYear()} Criado por MIUDAS BOAS
         </Footer>
    </Layout>
  );
};
export default App;