import React from 'react'
import {FaFacebook, FaInstagram,  FaWhatsapp} from 'react-icons/fa'

function RedesSocias({cor, desc}) {
  return (
    <p>
        <h1 style={{color:desc ? desc : null}}>NOS SIGA NAS REDES SOCIAIS</h1>
        <h1>
            <a style={{color:cor}} target="_blank" rel="noreferrer" aria-label="Nosso canal no whatsapp" href='https://whatsapp.com/channel/0029Vaa2tqmJuyA8E7GWoF2t'>
                <FaWhatsapp size={80} />
            </a>
            &nbsp;&nbsp;&nbsp;
            <a style={{color:cor}} target="_blank" rel="noreferrer" aria-label="Nossa pagina no instagram" href='https://www.instagram.com/miudasboasoficial'>
                <FaInstagram size={80} />
            </a>
            &nbsp;&nbsp;&nbsp;
            <a style={{color:cor}} target="_blank" rel="noreferrer" aria-label="Nossa pagina no facecebook" href='https://www.facebook.com/miudasboas'>
                <FaFacebook size={80} />
            </a>
        </h1>
    </p>
  )
}

export default RedesSocias