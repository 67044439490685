import React, {useEffect, useState, useCallback} from 'react';
import { Layout, Menu, theme, Image, Skeleton, QRCode, Typography, Tabs, Flex   } from 'antd';
import LOGO from '../assets/img/logo.png'
import LOGO2 from '../assets/img/logoB2.png'
import {useParams, Link, useNavigate} from 'react-router-dom'
import Gallery from 'react-photo-gallery'
import Carousel, { Modal, ModalGateway } from "react-images";
import CountUp from 'react-countup';
import {
  UserOutlined,
  HomeOutlined,
  TrophyOutlined,
  VideoCameraOutlined,
  FileImageOutlined
} from '@ant-design/icons';
import {AXIOS} from '../Config'

const {Title} = Typography
const { Header, Content, Footer } = Layout;

const items = [ 
  {icon: HomeOutlined,link: "Inicio"},
  {icon: UserOutlined,link: "Miudas"},
  // {icon: UserSwitchOutlined,link: "Massagistas"},
  {icon: TrophyOutlined,link: "Sobre"},
  // {icon: ContactsOutlined,link: "Contactos"},
  // {icon: ProfileOutlined,link: "Meu perfil"},
  // {icon: LockOutlined,link: "Cadastrar - se"},
].map((element, index) => ({
  key: `${element.link}`,
  icon: React.createElement(element.icon),
  label: `${element.link}`,
}));
const Miudas = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  
  const navigate = useNavigate();
  const {slug} = useParams()
  const [fotos, setFotos] = useState([])
  const [videos, setVideos] = useState([])
  const [info, setInfo] = useState({})
  const [servico, setServico] = useState({})
  const [atender, setAtender] = useState({})
  const [cache, setCache] = useState({})
  const [pagamento, setPagamento] = useState({})
  const [views, setViews] = useState([])
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [load, setLoad] = useState(true);

  useEffect(()=>{
    
    async function Axios(){
          
          
          const response = await AXIOS(`/miudas/${slug}`)
          console.log(response.data)
          setFotos(response.data.fotos.map(foto => ({src:foto.photo, width: 4, height: 4})))
          setVideos(response.data.videos)
          setInfo(response.data.miuda)
          setServico(response.data.servicos)
          setAtender(response.data.atendimento)
          setCache(response.data.cache)
          setPagamento(response.data.pagamento)
          setViews(response.data.views)
          
          setLoad(false)
          
          if(response.data.miuda !== 0) await AXIOS.post(`/miudas/${slug}`,{})

          
      }

      Axios()
      
      
  },[slug]) 

  const openLightbox = useCallback((event, { photo, index }) => {
      setCurrentImage(index);
      setViewerIsOpen(true);
    }, []);
  
  const closeLightbox = () => {
  setCurrentImage(0);
  setViewerIsOpen(false);
  };

  document.title = 'Miudas Boas - ' + info?.nome +' ' +info?.sobrenome
  document.querySelector('meta[property="og:image"]').setAttribute('content',info.photo);
  

  return (
    <Layout>
      <Header
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: "#FF1FA6",
          fontWeight: 500,
          marginBottom:0
        }}
      >
        <div className="demo-logo-vertical" style={{textAlign: 'center',}} >
          <Image
            width={130}
            src={LOGO}
          />
        </div>

        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={['2']}
          onClick={({ item, key, keyPath, domEvent }) => navigate("/"+key)}
          items={items}
          style={{
            flex: 1,
            minWidth: 0,
            backgroundColor: "#FF1FA6",
            fontSize:18
          }}
        />
      </Header>
      
      <Content
        style={{
          padding: '0 10px',
        }}
      >
        
        
        <div
          style={{
            background: colorBgContainer,
            minHeight: 280,
            padding: 7,
            borderRadius: borderRadiusLG,
            fontSize:"1.9em"
          }}
        >
          {info !== 0 ? <div className="organiza">
        <div>
        {load ? <Skeleton size="large" round /> : <div className="informacao" style={{fontSize:"1.4rem"}}>
              <div>
                <Title style={{textAlign:'left', fontWeight:700, fontSize: 35,color:"#FFF", textTransform:"uppercase"}}>{info.nome +' ' +info.sobrenome}</Title>
                <h4>{info.cidade}</h4>
              </div>
              <div>
                <h1 style={{textAlign:'left'}}><i className="fa fa-phone" aria-hidden="true"></i> <a style={{color:'#fff',textDecoration:'none'}} href={`tel:${info.tel}`}>{info.tel}</a></h1>
                <h4>Diz que me viste no Miudas Boas</h4>
              </div>
              <div>
                <h1 style={{textAlign:'left'}}><i className="fa fa-whatsapp" aria-hidden="true"></i>  <a rel="noreferrer" style={{color:'#fff',textDecoration:'none'}} href={`
https://wa.me/+${info.telwhats}?text=Ola%20${info.nome +' ' +info.sobrenome},%20eu%20vim%20do%20site%20das%20MIUDAS%20BOAS,%20estou%20interessado%20em%20voc%C3%AA`} target='_blank'>{info.telwhats}</a></h1>
                <h4>Clique para para comigo no whatsapp</h4>
              </div>
              <div>
                <h4>{new Date().getFullYear() - new Date(`${info.data_nasc}`).getFullYear() } anos | {info.altura} cm | {info.peso} kg | Pés {info.pe}</h4>
              </div>
                <details>
                    <summary>O QUE FAÇO</summary>
                    <span><b>Perfil</b>: {servico.perfil}</span><br/>
                    <span><b>Vaginal</b>: {servico.vaginal}</span><br/>
                    <span><b>Oral</b>: {servico.oral}</span><br/>
                    <span><b>Anal</b>: {servico.anal}</span><br/>
                    <span><b>Massagem</b>: {servico.massagem}</span><br/>
                    <span><b>Na Cama eu gosto de</b>: {servico.nacamagosta}</span><br/>
                    <span><b>Fantasias</b>: {servico.fantasia}</span><br/>
                </details>
                <details>
                    <summary>ATENDIMENTO</summary>
                    <span><b>Local</b>: {atender.onde}</span><br/>
                    <span><b>Periodo</b>: {atender.periodo}</span><br/>
                    <span><b>Momentos</b>: {atender.quando}</span><br/>
                    <span><b>Quais</b>: {atender.quem}</span><br/>
                </details>
                <details>
                    <summary>PAGAMENTO</summary>
                    <span><b>Hora normal</b>: {pagamento.hora}</span><br/>
                    <span><b>Meia hora</b>: {pagamento.meia_hora}</span><br/>
                    <span><b>Hora anal</b>: {pagamento.sexo_anal}</span><br/>
                    <span><b>Noite</b>: {pagamento.noite}</span><br/>
                    <span><b>Pagamento</b>: {cache.formas_pagamentos}</span><br/>
                </details>
                <div style={{textAlign:'center', color:"#fff", display:'flex', justifyContent:'center'}}>
                  <QRCode
                  errorLevel="H"
                  size={200}
                  color='#fff'
                  value={`https://miudasboas.com/${info.slug}`}
                  // icon={LOGO2}
                />
                </div>
            </div>} 
            {load ? <Skeleton size="large" round /> : <div className="detalhes" style={{fontSize:"1.4rem"}}>
              <b>Visitas de hoje</b>: <CountUp end={views.filter(v=>v.datehour.includes(new Date().toISOString().split('T')[0])).length} separator="." /><br/>
              <b>Visitas total</b>: <CountUp end={views.length} separator="." /><br/>
              <b>Fotos</b>: <CountUp end={fotos.length} separator="." /><br/>
              <b>Videos</b>: <CountUp end={videos.length} separator="." /><br/>
            </div>}
        </div>
        {load ? <Skeleton size="large" round /> : fotos.length > 0 
          ? <Tabs
              color='#000'
              type="card"
              size="large"
              style={{width: '100%'}}
              tabBarStyle={
                {
                  color: '#000',
                  borderColor: 'red',
                }
              }
              centered
              defaultActiveKey="1"
              items={[FileImageOutlined, VideoCameraOutlined].map((Icon,i) => {
      
                if(i === 0){
                  return {
                    key: i,
                    label: `Imagens (${fotos.length})`,
                    children: <Gallery photos={fotos} onClick={openLightbox} margin={10}  />,
                    // children: <Gallery photos={fotos} onClick={openLightbox} margin={10}  />,
                    icon: <Icon />
                  };
                }else{
                  return {
                    key: i,
                    label: `Videos (${videos.length})`,
                    children: <Flex wrap="wrap" gap="small">
                                {videos.map(video => 
                                  <video width="320" height="240" className='video' controls>
                                    <source src={video.video} type="video/mp4" />
                                    <source src={video.video} type="video/ogg" />
                                  </video>
                                )}
                              </Flex>,
                    icon: <Icon />
                  };
                }
              })}
            /> 
          : <div className="semfotos">
              <h1>Sem fotos no momento</h1>
              <h4>Pedimos desculpas pelo ocorrido, em breve visualizará as fotos da nossa acompanhate!</h4>
              <img className="infofoto" alt={info.photo} src={info.photo} />
            </div>
        }
        
      </div>
      :<div className="semfotos">
          <h1>Acompanhante ou pagina não encontrada</h1>
          <h4>A pagina que tentou aceder não foi encontrada, por favor tente mais logo ou mude sua navegação</h4>
          <Link to="/">Voltar para o inicio</Link>
      </div>}
      
      
      {load ? <Skeleton size="large" round /> : <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={fotos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>}

        </div>
      </Content>
      <Footer
           style={{
             textAlign: 'center',
             color:"#FFFFFF",
             fontSize:20,
             backgroundColor:"#364D79"
         }}
         >
         <div className="demo-logo-vertical" style={{textAlign: 'center',}} >
          <Image
            width={250}
            src={LOGO2}
            />
        </div>
            Todos os direitos reservados ©{new Date().getFullYear()} Criado por MIUDAS BOAS
      </Footer>
    </Layout>
  );
};
export default Miudas;