import React, {useEffect, useState} from 'react';
import {Header, RedesSocias, Footer, Load} from '../components'
import logo from '../assets/img/logoC.png'
import {AXIOS} from '../Config'

function Contacto() {
  const [load, setLoad] = useState(true);
  const [mensagem, setMensagem] = useState({cor:'',msg:''});
  const [dados,setDados] = useState({nome:'', sobrenome:'', email:'', terminal:'', pais:'', cidade:'', msg:''})

  useEffect(()=>{
    document.title = 'Miudas Boas -  Contactos'
    async function Axios(){
      // const response = await AXIOS.get('/')
      setLoad(false)
      
    }

    Axios()
    
},[]) 
const enviar = async ()=>{
  if(dados.nome && dados.sobrenome && dados.email && dados.terminal && dados.msg){
    const response = await AXIOS.post('/contacto',dados)
    setMensagem(response.data)
    setDados({nome:'', sobrenome:'', email:'', terminal:'', pais:'', cidade:'', msg:''})
  }else{
    setMensagem({cor:'#900', msg:'Preencha todo os campos por favor'})
  }

  setTimeout(()=>
  setMensagem({cor:'',msg:''})
  , 2000)
}

  if(load){
    return <Load/>
  }

  return (
    <>
      <Header />
      <div className="container-fluid bg" >
              <h1>Contacte - nos</h1>
              <h3>Deixa-nos uma mensagem para teres todas informações ou solicitar as nossas acompanhantes</h3>
          </div>
                  <div className="col-sm-89">
                    <div className="form-p">
                   
                    
                      <div className="form">
                          <input  autocomplete="false" type="text" name="nome" onBlur={(e) => {setDados({...dados,nome: e.target.value})}} required="" className="form-control" placeholder="Primeiro Nome" />
                          <input required="" type="text" name="sobrenome" onBlur={(e) => {setDados({...dados,sobrenome: e.target.value})}} className="form-control" placeholder="Ultimo Nome" />
                          <input  required="" type="email" onBlur={(e) => {setDados({...dados,email: e.target.value})}} name="email"
                              className="form-control" placeholder="Seu Email" />
                          <input  required="" type="tel"
                              className="form-control" onBlur={(e) => {setDados({...dados,terminal: e.target.value})}} name="terminal" placeholder="Seu Terminal" />
                            <div className="form-group" style={{width:"100%"}}>

                                <input  name="pais" onBlur={(e) => {setDados({...dados,pais: e.target.value})}} type="text" list="pais"
                                    required="" className="form-control" style={{marginRight:10}} placeholder="Teu País" />
                                <datalist id="pais">
                                    <option>Angola</option>
                                    <option>Moçambique</option>
                                    <option>Outro</option>
                                </datalist>

                                          <input  name="provincia" onBlur={(e) => {setDados({...dados,cidade: e.target.value})}} type="text"
                                              list="provincias" required="" className="form-control" placeholder="Tua Cidade" />
                                          <datalist id="provincias">
                                              <option>Bengo</option>
                                              <option>Benguela</option>
                                              <option>Bié</option>
                                              <option>Cabinda</option>
                                              <option>Cunene</option>
                                              <option>Cuando Cubango</option>
                                              <option>Huíla</option>
                                              <option>Huambo</option>
                                              <option>Kwanza Norte</option>
                                              <option>Kwaza Sul</option>
                                              <option>Luanda</option>
                                              <option>Lunda Norte</option>
                                              <option>Lunda Sul</option>
                                              <option>Malanje</option>
                                              <option>Moxico</option>
                                              <option>Namibe</option>
                                              <option>Uige</option>
                                              <option>Zaire</option>
                                          </datalist>
                                      </div>
                                      <textarea onBlur={(e) => {setDados({...dados,msg: e.target.value})}}  required="" name="msg" id="" cols="30"
                                           rows="10" className="border-0 form-control"
                                          placeholder="Sua Mensagem"></textarea>
                                          <button onClick={enviar}
                                        className="btn btn-primary py-3 px-4 border-0">Enviar a Mensagem</button>

                                        <div className="mensagem" style={{backgroundColor:mensagem.cor}}>{mensagem.msg}</div>
                                    </div>
                                    <div className="logoo">
                                    <img src={logo} className="logo" alt="logo miudas boas" />

                                    </div>
                                    </div>    
                                    <RedesSocias cor='#FFF' desc='#FFF'/>  
                                    
                                  
                  <div className="container-fluid text-center mt-3 text-white text-xl">
                      <h4 style={{color:'#99399b'}}>Poderá nos ligar neste terminal 
                        <a style={{color:'#fff'}} rel="noreferrer" target="_blank" href="tel://+244921442476">
                              <br/>
                              <i className="fa fa-phone"></i>&nbsp;
                              (+244) 921442476
                        </a>
                      </h4>
                  </div>
                  </div>
                  
      <Footer />
    </>
  )
}


export default Contacto
