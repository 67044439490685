
import axios  from 'axios';


const config =  {
    // HOST_API: "http://127.0.0.1:1010/api/v1", 
    HOST_API: "https://miudasboas.com/api/v1", 
    COOKIE_SESSION: {
        CLIENT:"login$PORTALINTELIZE2023#_28fc113b2d8229071bec2afa9e653157=",
        ADM:"login$PORTALINTELIZE2023#_c2331389c53b6d52f1e5344590412795=",
        SECURITY_CODE_ENTITY: "security$Intelize#@_skj87sjsjskkk#kkk=",
        ID_CODE_ENTITY: "security$Intelize_payment#@_skj87sjsjs0098Hkkk#kkk=",
    },
    COMPANY:{
        NAME:"INTELIZE INVESTIMENTOS",
        CONTACTS:[],
        EMAILS:["suporte@intelize.ao", "comercial@intelize.ao", "projectos@intelize.ao"],
        ADDRESS: "Luanda, Talatona, Atlântico Business, Escritorio nº 28" 
    }
} 

const serviceUrl = config.HOST_API

const configAxios = {
  baseURL : serviceUrl,
  maxBodyLength : Infinity,
  insecureHTTPParser: true,
  httpAgent: true
}

const instance = axios.create(configAxios)

export {instance as AXIOS, config as CONFIGURATION};