import React, {useEffect, useState} from 'react';
import { Layout, Menu, Button, theme, Image, Typography, Card, Flex, Col, Row,
  Form,
  Input,
  Skeleton,
  Select, } from 'antd';
import LOGO from '../assets/img/logo.png'
import LOGO2 from '../assets/img/logoB2.png'
import {AXIOS} from '../Config'
import {useNavigate} from 'react-router-dom'
import {
  UserOutlined,
  EnvironmentOutlined,
  HomeOutlined,
  TrophyOutlined
} from '@ant-design/icons';


const { Header, Content, Footer } = Layout;
const { Title } = Typography;
const { Meta } = Card;

const items = [ 
  {icon: HomeOutlined,link: "Inicio"},
  {icon: UserOutlined,link: "Miudas"},
  // {icon: UserSwitchOutlined,link: "Massagistas"},
  {icon: TrophyOutlined,link: "Sobre"},
  // {icon: ContactsOutlined,link: "Contactos"},
  // {icon: ProfileOutlined,link: "Meu perfil"},
  // {icon: LockOutlined,link: "Cadastrar - se"},
].map((element, index) => ({
  key: `${element.link}`,
  icon: React.createElement(element.icon),
  label: `${element.link}`,
}));
const Miudas = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [miudas,setMiudas] = useState([])
  const [pesquisa,setPesquisa] = useState({Nome:"",Cidade:"",Idade:"", Sexo:""})
  const navigate = useNavigate();
  const [load, setLoad] = useState(true);

  useEffect(()=>{
    document.title = 'Miudas Boas -  Nossas Miudas'
    async function Axios(){
        const response = await AXIOS.get('/miudas/')
        setMiudas(response.data.miudas)
        setLoad(false)
          
      }

      Axios()
      
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      
      
  },[]) 

  const mais = async (limit)=>{
    const response = await AXIOS.get('/miudas/limit/'+limit)
    console.log(response.data)
    setMiudas(response.data.miudas)
  }

  const pesquisar = async ({Nome, Cidade, Idade, Sexo})=>{
    const response = await AXIOS.post(`/miudas/pegar`,{Nome,Cidade,Idade, Sexo})
    console.log(response.data)
    setMiudas(response.data.miudas)
    setLoad(false)
  }

  return (
    <Layout>
      <Header
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: "#FF1FA6",
          fontWeight: 500,
          marginBottom:0
        }}
      >
        <div className="demo-logo-vertical" style={{textAlign: 'center',}} >
          <Image
            width={130}
            src={LOGO}
          />
        </div>

        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={['2']}
          onClick={({ item, key, keyPath, domEvent }) => navigate("/"+key)}
          items={items}
          style={{
            flex: 1,
            minWidth: 0,
            backgroundColor: "#FF1FA6",
            fontSize:18
          }}
        />
      </Header>
      
      <Content
        style={{
          padding: '0 10px',
        }}
      >
        
        
        
          
          <Row 
            style={{
              // padding: 24,
              textAlign: 'center',
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
              fontSize:"1.7em"
            }}

            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >

            <Col className="gutter-row lado-filtro" span={4}>

            <Form
            labelCol={{ span: 4, }}
            wrapperCol={{ flex: 1, }}
            layout="horizontal"
            initialValues={{ size: "large", }}
            size={"large"}
            style={{ maxWidth: "100%", fontSize: 40, marginTop:15}}
          >
        
              <Form.Item name="Nome" initialValue="" style={{fontSize:20}}>
                <Input placeholder='Nome' onChange={e => setPesquisa(prev => ({...prev, Nome: e.target.value}))} width={200} />
              </Form.Item>
              <Form.Item name="Sexo" initialValue="">
                <Select placeholder="Sexo" onChange={e => setPesquisa(prev => ({...prev, Sexo: e }))}>
                  <Select.Option value="">Qualquer</Select.Option>
                  <Select.Option value="F">Mulheres</Select.Option>
                  <Select.Option value="M">Homens</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="Cidade" initialValue="">
                <Select placeholder="Cidade" onChange={e => setPesquisa(prev => ({...prev, Cidade: e }))}>
                  <Select.Option value="">Todas</Select.Option>
                  <Select.Option value="Luanda">Luanda</Select.Option>
                  <Select.Option value="Benguela">Benguela</Select.Option>
                  <Select.Option value="Huambo">Huambo</Select.Option>
                  <Select.Option value="Namibe">Namibe</Select.Option>
                  <Select.Option value="Huila">Huila</Select.Option>
                  <Select.Option value="Malange">Malange</Select.Option>
                  <Select.Option value="Maputo">Maputo</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="Idade" initialValue="">
                <Select placeholder="Idade" onChange={e => setPesquisa(prev => ({...prev, Idade: e}))}>
                  <Select.Option value="">Todas</Select.Option>
                  <Select.Option value="18">18</Select.Option>
                  <Select.Option value="19">19</Select.Option>
                  <Select.Option value="20">20</Select.Option>
                  <Select.Option value="21">21</Select.Option>
                  <Select.Option value="22">22</Select.Option>
                  <Select.Option value="23">23</Select.Option>
                  <Select.Option value="24">24</Select.Option>
                  <Select.Option value="25">25</Select.Option>
                  <Select.Option value="26">26</Select.Option>
                  <Select.Option value="27">27</Select.Option>
                  <Select.Option value="28">28</Select.Option>
                  <Select.Option value="29">29</Select.Option>
                  <Select.Option value="30">30</Select.Option>
                  <Select.Option value="31">31</Select.Option>
                  <Select.Option value="32">32</Select.Option>
                  <Select.Option value="33">33</Select.Option>
                  <Select.Option value="34">34</Select.Option>
                  <Select.Option value="35">35</Select.Option>
                  <Select.Option value="36">36</Select.Option>
                  <Select.Option value="37">37</Select.Option>
                  <Select.Option value="38">38</Select.Option>
                  <Select.Option value="39">39</Select.Option>
                  <Select.Option value="40">40</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item>
                <Button onClick={async ()=> {setLoad(true); await pesquisar(pesquisa)}} style={{backgroundColor:"#FF1FA6", color:"white"}} shape="round" size='large'>Pesquisar</Button>
              </Form.Item>
            </Form>
            </Col> 
            
            <Col className="gutter-row lado-miudas" span={20}>
              <Title style={{textAlign:"left",color:"#FF1FA6",fontWeight:900,fontSize:50, marginTop:0}}>ENCONTRE A MIUDAS IDEAL PARA SÍ</Title>
              <Title level={3} style={{textAlign:"left", marginTop:-20, marginBottom:50,fontSize:30}}>Pesquise em nossa plataforma filtrando as caracteriasticas ideias para sí</Title>
              
              {load ? <Skeleton size="large" round /> : <Flex wrap='wrap' className='miudasBB' justify='center' gap="large" style={{marginBottom:100}}>
              
                
                  {miudas.length ? miudas.map(el=>(
                      <Card
                        hoverable
                           style={{ width: 280, fontSize:20 }}
                           cover={<Image src={el.photo} height={300} />}
                           className="miudass"
                        >
                          <Meta title={<Title level={2} style={{fontWeight:900, textTransform:'uppercase', fontSize:20}}>{el.nome + ' ' + el.sobrenome}</Title>}/>
                          <Title style={{marginTop:5}} level={5}>@{el.slug} | <EnvironmentOutlined /> {el.cidade}</Title>
                          <Button className='botao-perfil' onClick={()=>navigate(`/${el.slug}`)} style={{backgroundColor:"#FF1FA6", color:"white", marginTop:-30}} shape="round" icon={<UserOutlined />} size="middle" >Meu perfil</Button>
                        </Card>
                        ))
                    : <Title>Pesquisa não encontrada ...</Title>}

              </Flex>}
                    <Button onClick={()=>mais(miudas.length + 4)} style={{backgroundColor:"#444", color:"white", marginBottom:10}} size='large'>Mais miudas</Button>
          </Col>
        </Row>
      </Content>
      <Footer
           style={{
             textAlign: 'center',
             color:"#FFFFFF",
             fontSize:20,
             backgroundColor:"#364D79"
         }}
         >
         <div className="demo-logo-vertical" style={{textAlign: 'center',}} >
          <Image
            width={250}
            src={LOGO2}
            />
        </div>
            Todos os direitos reservados ©{new Date().getFullYear()} Criado por MIUDAS BOAS
         </Footer>
    </Layout>
  );
};
export default Miudas;